<template>
  <div>
    <v-select
      dense
      v-model="processor.name.S"
      :items="endpointEnum.tasks"
      label="Task *"
      required
      @change="$emit('addProcessorTask', $event)"
    ></v-select>
    <template v-if="processor.name.S === 'tasks.s3Keeper'">
      <processor-s3-keeper-form v-model="processor.optionTemplate" />
    </template>
    <template v-else-if="processor.name.S === 'tasks.jinja'">
      <processor-jinja-form v-model="processor.optionTemplate" />
    </template>
    <template v-else-if="processor.name.S === 'tasks.httpRequest'">
      <processor-http-request-form v-model="processor.optionTemplate" />
    </template>
    <template v-else-if="processor.name.S === 'tasks.windowAggregator'">
      <processor-window-aggregator-form v-model="processor.optionTemplate" />
    </template>
    <template v-else-if="processor.name.S === 'tasks.iterator'">
      <processor-iterator-form v-model="processor.optionTemplate" />
    </template>
    <template v-else-if="processor.name.S === 'tasks.nodejs'">
      <processor-nodejs-form v-model="processor.optionTemplate" />
    </template>
    <label>Guard</label>
    <bool-editor :min-line="1" :max-line="1" :value="processor.guard || {}" @input="processor.guard = $event" />
    <small>*indicates required field</small>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import endpointTemplateForm from '@/template/endpointTemplate.json'

export default {
  name: 'EndPointProcessorForm',
  model: {
    prop: 'processor'
  },
  components: {
    ProcessorHttpRequestForm: () => import('@/components/end-point/processors/ProcessorHttpRequestForm'),
    ProcessorS3KeeperForm: () => import('@/components/end-point/processors/ProcessorS3KeeperForm'),
    ProcessorJinjaForm: () => import('@/components/end-point/processors/ProcessorJinjaForm'),
    ProcessorIteratorForm: () => import('@/components/end-point/processors/ProcessorIteratorForm'),
    ProcessorWindowAggregatorForm: () => import('@/components/end-point/processors/ProcessorWindowAggregatorForm'),
    ProcessorNodejsForm: () => import('@/components/end-point/processors/ProcessorNodejsForm'),
    'bool-editor': () => import('@/components/BoolEditor.vue')
  },
  props: {
    processor: {
      type: Object,
      default () {
        return {
        }
      },
      required: true
    },
    isCreated: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      valid: true,
      dialog: false,
      guardOperator: {
        index: -1,
        guard: null
      },
      incorrectIndex: -1,
      argsIndex: 0,
      args: []
    }
  },
  methods: {
    addArgument: function (args) {
      if (args.length < this.processor.operator.length) {
        this.processor.operator.pop()
      } else {
        this.processor.operator.push({
          index: this.processor.operator.length > 0 ? this.processor.operator[this.processor.operator.length - 1].index + 1 : 0,
          value: args[args.length - 1]
        })
      }
    },
    editGuard: function (event, index, indexValue) {
      this.guardOperator = {
        index: index,
        indexValue: indexValue,
        guard: event
      }
    },
    deleteGuard: function (index) {
      this.processor.operator.splice(index, 1)
    },
    updateGuard: function () {
      this.processor.operator.splice(this.guardOperator.index, 1, {
        index: this.guardOperator.indexValue,
        value: this.guardOperator.guard
      })
    },
    counterRequire: function (lessLength, moreLength) {
      if (lessLength && moreLength) {
        return v => ((v || '').length >= lessLength && (v || '').length <= moreLength) || `field must be ${lessLength}-${moreLength} characters`
      } else {
        return v => (v || '').length <= lessLength || `field must be ${lessLength} characters`
      }
    },
    guardRequired: function (value) {
      const guards = value.map(val => val.value)
      const logicOperator = ['and', 'or', 'not', '==', '>=', '<', '>', '<=', '!=']
      if (value.length > 0) {
        if (guards.length % 2 === 0) {
          return 'value is not couple of operator'
        } else {
          const guardFilter = guards.filter((g, id) => (id + 1) % 2 === 0).every(ge => logicOperator.includes(ge))
          if (!guardFilter) return 'operator incorrect format.'
        }
      }
      return true
    },
    destroy: function () {
      // this.$destroy()
    },
    onError: function () {
      console.log('json error')
    }
  },
  computed: {
    ...mapGetters(['fiwareService']),
    endpointEnum: function () {
      return endpointTemplateForm.enum
    },
    fieldRequire: function () {
      return v => !!v || 'field is required'
    },
    azAZ09Require: function () {
      return v => /^[A-Za-z0-9]*$/.test(v) || 'field must be valid'
    }
  },
  watch: {
  },
  created () {}
}
</script>
